<template>
    <qrcode-stream 
      :camera="camera" 
      @decode="onDecode" 
      @init="onInit">      
      <div v-if='showHelperMessage' 
        class='helper-message absolute h-full w-full text-center top-0 l-0 flex flex-col justify-center uppercase'>
        <scan-icon class='mx-auto h-64 w-64 text-white opacity-50' stroke-width='0.5' />
        <span class='text-white font-semibold'>{{ scanHelperMessage }}</span>
      </div>
      <!-- <div v-if="validationSuccess" 
        class="validation-success">
        This is a URL
      </div> -->
      <div v-if="validationFailure" 
        class="validation-failure">
        This is NOT a URL!
      </div>
      <div v-if="validationPending" 
        class="validation-pending">
        Long validation in progress...
      </div>
    </qrcode-stream>
</template>

<script>
// import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import { QrcodeStream } from 'vue-qrcode-reader'
import { ScanIcon } from 'vue-tabler-icons'

export default {
  name: 'QrScanner',
  components: { 
    QrcodeStream,
    ScanIcon,
  },
  props: {
    scanHelperMessage: {
      type: String,
      default: 'Scan QR Code Here',
    },
    validationStrings: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      isValid: undefined,
      camera: 'auto',
      result: null,
    }
  },
  watch: {
    result: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.$emit('scanned-qr', newVal)
        }
      }
    },
  },
  computed: {
    showHelperMessage () {
      return true
    },
    validationPending () {
      return this.isValid === undefined
        && this.camera === 'off'
    },

    validationSuccess () {
      return this.isValid === true
    },

    validationFailure () {
      return this.isValid === false
    }
  },

  methods: {
    onInit (promise) {
      promise
        .catch(console.error)
        .then(this.resetValidationState)
    },
    resetValidationState () {
      this.isValid = undefined
    },
    async onDecode (content) {
      this.result = content
      this.turnCameraOff()

      this.isValid = content.startsWith('http')
      // this.isValid = this.validationStrings.length === 0 || this.validationStrings.every(str => content.includes(str))
      // some more delay, so users have time to read the message
      // await this.timeout(2000)
      this.turnCameraOn()
      this.resetValidationState()
    },
    turnCameraOn () {
      this.camera = 'auto'
    },
    turnCameraOff () {
      this.camera = 'off'
    },
    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    }
  }
}
</script>

<style scoped>
.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, .8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.validation-success {
  color: green;
}
.validation-failure {
  color: red;
}

</style>